.wrapper {
    margin-top: 16px;
    margin-bottom: 24px;
    font: var(--font);
    font-size: 14px;
}

.caret {
    color: var(--mainBlue);
    display: inline-flex;
    margin: 0 6px;
    transform: scaleY(0.8) scaleX(0.5) scale(2);
}
