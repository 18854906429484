:root {
    --font: 'Roboto', sans-serif;
    --deepBlue: #009ccf;
    --mainBlue: #0B78A2;
    --accentBlue: #1aabff;
    --bullet: #262626;
    --white: #ffffff;
    --black: #000000;
    --blackText: #070707;
    --lightGrey: #c9c9c9;
    --grey: #7e7e7e;
    --borderGrey: #707070;
    --descriptionGrey: #8f8f8f;
    --linkColor: #4fbbe4;
    --previewTextColor: #f9f9f9;
    --primaryDark: #0B78A2;
}

body {
    margin: 8px;
    padding-bottom: 140px;
    font-family: var(--font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
    display: none; /* scrollbar hidden for Chrome */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: var(--mainBlue);
}

a:visited {
    color: var(--mainBlue);
}
