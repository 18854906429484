.wrapper {
    position: relative;
    width: 40%;
    margin: 0 auto;

    .searchInput {
        padding-left: 35px;
        font-size: 14px;
        font-family: var(--font);
    }

    .icon {
        color: var(--black);
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }
}

.hint {
    text-align: left;
    font-family: var(--font);
    font-size: 14px;
    margin: 10px auto 0px;
    width: 40%;
}
