.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 48px auto 68px;

    .title {
        font-family: var(--font);
        font-style: normal;
        color: var(--black);
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.25px;

    }
}
